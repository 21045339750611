exports.components = {
  "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-about-mdx": () => import("./../../../src/components/Template.tsx?__contentFilePath=/home/runner/work/website/website/src/markdown-pages/about.mdx" /* webpackChunkName: "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-about-mdx" */),
  "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-actionpact-mdx": () => import("./../../../src/components/Template.tsx?__contentFilePath=/home/runner/work/website/website/src/markdown-pages/actionpact.mdx" /* webpackChunkName: "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-actionpact-mdx" */),
  "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-alignment-mdx": () => import("./../../../src/components/Template.tsx?__contentFilePath=/home/runner/work/website/website/src/markdown-pages/alignment.mdx" /* webpackChunkName: "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-alignment-mdx" */),
  "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-birthday-mdx": () => import("./../../../src/components/Template.tsx?__contentFilePath=/home/runner/work/website/website/src/markdown-pages/birthday.mdx" /* webpackChunkName: "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-birthday-mdx" */),
  "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-chopin-mdx": () => import("./../../../src/components/Template.tsx?__contentFilePath=/home/runner/work/website/website/src/markdown-pages/chopin.mdx" /* webpackChunkName: "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-chopin-mdx" */),
  "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-intronet-mdx": () => import("./../../../src/components/Template.tsx?__contentFilePath=/home/runner/work/website/website/src/markdown-pages/intronet.mdx" /* webpackChunkName: "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-intronet-mdx" */),
  "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-parents-mdx": () => import("./../../../src/components/Template.tsx?__contentFilePath=/home/runner/work/website/website/src/markdown-pages/parents.mdx" /* webpackChunkName: "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-parents-mdx" */),
  "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-pioneers-mdx": () => import("./../../../src/components/Template.tsx?__contentFilePath=/home/runner/work/website/website/src/markdown-pages/pioneers.mdx" /* webpackChunkName: "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-pioneers-mdx" */),
  "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-venture-mdx": () => import("./../../../src/components/Template.tsx?__contentFilePath=/home/runner/work/website/website/src/markdown-pages/venture.mdx" /* webpackChunkName: "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-venture-mdx" */),
  "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-will-to-thrive-mdx": () => import("./../../../src/components/Template.tsx?__contentFilePath=/home/runner/work/website/website/src/markdown-pages/will-to-thrive.mdx" /* webpackChunkName: "component---src-components-template-tsx-content-file-path-home-runner-work-website-website-src-markdown-pages-will-to-thrive-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

